@import "~bootstrap/scss/bootstrap";
@import "assets/scss/style.scss";
// .pcoded-navbar.menupos-fixed {
//   min-height: 63vh;
//   height: calc(100vh - 40px);
// }
.pcoded-main-container {
  min-height: 63vh;
  height: calc(100vh - 66px);
}

.pcoded-content {
  position: relative;
  display: block;
  //   padding: 25px;
  margin-top: 0px;
}
